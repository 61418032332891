import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SimpleEventListDTO} from '../model/dto/simple-event-list.dto';

@Injectable({
  providedIn: 'root'
})
export class ListActiviteDataService {
  private readonly dataAllActiviteList = new BehaviorSubject(new Array<SimpleEventListDTO>());
  public allActiviteMessager = this.dataAllActiviteList.asObservable();
  private readonly dataActiviteList = new BehaviorSubject(new Array<SimpleEventListDTO>());
  public activiteMessager = this.dataActiviteList.asObservable();
  private readonly dataIsLoading = new BehaviorSubject(true);
  public isLoadingMessager = this.dataIsLoading.asObservable();

  changeEventList(eventList: SimpleEventListDTO[]) {
    this.dataActiviteList.next(eventList);
  }

  changeAllEventList(eventList: SimpleEventListDTO[]) {
    this.dataAllActiviteList.next(eventList);
  }

  changeIsLoading(bool: boolean) {
    this.dataIsLoading.next(bool);
  }
}
