import {Component, OnInit} from '@angular/core';
import {ICellRendererAngularComp} from '@ag-grid-community/angular';

@Component({
  selector: 'app-name-cell-renderer',
  templateUrl: './name-cell-renderer.component.html',
  styleUrls: ['./name-cell-renderer.component.scss'],
  standalone: true
})
export class NameCellRendererComponent implements OnInit, ICellRendererAngularComp {
  private static readonly defaultImgPath = '../../../assets/images/default-profil-circle.svg';
  public lastname: string;
  public firstname: string;
  public fullname: string[];
  public imgPath: string;

  ngOnInit() {
    this.imgPath = NameCellRendererComponent.defaultImgPath;
  }

  agInit(params): void {
    this.fullname = params.data.name.split(' ');
    this.firstname = this.fullname[0];
    this.lastname = this.fullname[1];
  }

  refresh(): boolean {
    return false;
  }
}
